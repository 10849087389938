<template>
    <div class="student-manage">
        <table-page-template
            v-bind="templateConfig"
            :paging-config.sync="getStudentListParams"
            :total-count="totalCount"
            @page-change="getStudentList"
        >
            <template #head>
                <div class="operate-box">
                    <div class="left-filter">
                        <div class="label">选择班级</div>
                        <el-select v-model="getStudentListParams.banji_id" placeholder="请选择班级" clearable @change="selectClass">
                            <el-option v-for="item in classOptions" :key="item.value" :label="item.label" :value="item.value">
                            </el-option>
                        </el-select>
                    </div>
                    <div class="right-operate-button">
                        <el-button class="plain-button" @click="downloadTemplate">下载模板</el-button>
                        <el-button class="plain-button" @click="openImportDialog">导入学生</el-button>
                        <el-button type="primary" @click="createStudent">创建学生</el-button>
                    </div>
                </div>
            </template>
            <template #tableOperate="{ item }">
                <el-link type="primary" :underline="false" @click="resetPassword(item)">重置密码</el-link>
                <el-link type="primary" :underline="false" @click="editStudent(item)">编辑</el-link>
                <el-link type="danger" :underline="false" @click="delStudent(item)">删除</el-link>
            </template>
        </table-page-template>
        <my-dialog
            class="operate-student"
            :title="operateDialogTitle"
            :is-show.sync="operateDialog"
            :confirm-handler="confirmOperate"
            @closeDialog="clearFormData"
            :top="40"
            padding="40px 0 18px"
        >
            <div class="form-box">
                <pack-el-form
                    ref="elForm"
                    :rules="rules"
                    :params.sync="operateParams"
                    :form-config="formConfig"
                    label-width="65"
                ></pack-el-form>
            </div>
        </my-dialog>
        <my-dialog class="delete-student" title="删除学生" :is-show.sync="deleteDialog" :confirm-handler="confirmDelete">
            <div class="content">是否删除此学生, 删除后将无法恢复</div>
        </my-dialog>
        <my-dialog
            class="import-student"
            title="导入学生"
            :is-show.sync="importDialog"
            padding="50px 0 40px"
            :confirm-handler="openFile"
            :button-config="importConfig.dialogButtonConfig"
            @closeDialog="clearImportForm"
        >
            <pack-el-form
                ref="importElForm"
                :rules="rules"
                :params.sync="importParams"
                :form-config="importConfig.formConfig"
                label-width="65"
                input-width="250"
            ></pack-el-form>
        </my-dialog>
        <div class="hidden-box">
            <input class="file" type="file" ref="file" accept=".xlsx" @change="confirmImport" />
        </div>
    </div>
</template>

<script>
import TablePageTemplate from "components/common/TablePageTemplate";
import PackElForm from "components/common/PackElForm";
import MyDialog from "components/common/MyDialog";
import {
    uploadStudentAvatar,
    operateStudent,
    deleteStudent,
    downloadStudentTemplate,
    getStudentListData,
    importStudent,
    getClassListData,
    resetStudentPassword,
} from "@/utils/apis";
export default {
    name: "Index",
    data() {
        return {
            tableData: [],
            getStudentListParams: {
                paging: 1,
                page: 1,
                pageSize: 10,
                banji_id: "",
            },
            operateParams: {
                nickname: "",
                username: "",
                password: "",
                avatar: "",
                banji_id: "",
                user_id: "",
            },
            importParams: {
                banji_id: "",
                file: "",
            },
            rules: {
                nickname: [
                    { required: true, message: "请输入学生名称", trigger: "blur" },
                    { min: 2, message: "账号的长度不能小于2", trigger: "blur" },
                ],
                username: [
                    { required: true, message: "请输入账号", trigger: "blur" },
                    { min: 2, message: "管理员名称的长度不能小于2", trigger: "blur" },
                ],
                banji_id: [{ required: true, message: "请选择所属班级", trigger: "change" }],
                password: [{ min: 6, message: "密码的长度不能小于6", trigger: "blur" }],
            },
            classOptions: [],
            totalCount: 1,
            deleteId: -1,
            //dialog
            deleteDialog: false,
            operateDialog: false,
            importDialog: false,
        };
    },
    computed: {
        host() {
            let host = localStorage.getItem("hosturl");
            return !host ? location.origin : host;
        },
        operateDialogTitle() {
            return this.operateParams.user_id ? "编辑学生" : "创建学生";
        },
        templateConfig() {
            return {
                tableData: this.tableData,
                tableConfig: [
                    { label: `学生名称`, prop: "nickname" },
                    { label: "头像", prop: "avatar", isImg: true, align: "center" },
                    { label: "学生账号", prop: "username", align: "center" },
                    { label: "班级名称", prop: "banji_name", align: "center" },
                    { label: "操作", width: 300, align: "center" },
                ],
            };
        },
        importConfig() {
            return {
                formConfig: [
                    {
                        label: "选择班级",
                        prop: "banji_id",
                        type: "select",
                        placeholder: "请选择班级",
                        options: this.classOptions,
                    },
                ],
                dialogButtonConfig: [{ text: "取消" }, { text: "导入模板" }],
            };
        },
        formConfig() {
            const config = [
                {
                    label: "学生名称",
                    prop: "nickname",
                    placeholder: "2-18个字符",
                    max: 18,
                },
                {
                    label: "账号",
                    prop: "username",
                    placeholder: "2-20个字符",
                    max: 20,
                },

                {
                    label: "密码",
                    prop: "password",
                    placeholder: "6-20个字符",
                    max: 20,
                },
                {
                    label: "头像",
                    prop: "avatar",
                    type: "img",
                    field: "student_avatar",
                    uploadPath: "student_manage/upload",
                    clear: this.clearAvatar,
                    uploadSuccess: this.uploadSuccess,
                    uploadErr: this.uploadErr,
                },
            ];
            config.splice(3, 0, this.importConfig.formConfig[0]);
            return config;
        },
    },
    components: {
        TablePageTemplate,
        MyDialog,
        PackElForm,
    },
    methods: {
        init() {
            this.getStudentList();
            this.getClassList();
        },
        async getStudentList() {
            let res = await getStudentListData(this.getStudentListParams);
            this.tableData = res.data.list;
            this.totalCount = res.data.total;
        },
        async getClassList() {
            const res = await getClassListData({ paging: 0 });
            this.classOptions = res.data.list.map((item) => ({ label: item.banji_name, value: item.banji_id }));
        },
        createStudent() {
            this.$delete(this.operateParams, "user_id");
            this.rules.password.push({ required: true, message: "请输入密码", trigger: "blur" });
            this.operateDialog = true;
        },
        resetPassword(item) {
            this.$confirm("重置密码，默认123456", "提示消息", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
                center: true,
            }).then(async () => {
                const params = { user_id: item.user_id };
                const res = await resetStudentPassword(params);
                if (res.code === 200) {
                    this.$message.success(res.msg);
                    this.getStudentList();
                }
            }).catch(() => {
                this.$message.info("已取消重置");
            });
        },  
        editStudent(item) {
            Object.assign(this.operateParams, item);
            this.operateDialog = true;
        },
        async confirmOperate() {
            this.$refs.elForm.validate(async () => {
                let avatar = this.operateParams.avatar ? this.operateParams.avatar.replace(this.host, "") : "";
                let res = await operateStudent({
                    ...this.operateParams,
                    avatar,
                });
                const msg = res.code == 200 ? "操作成功" : res.msg;
                this.$message.success(msg);
                this.operateDialog = false;
                this.getStudentList();
            });
        },
        delStudent(item) {
            this.deleteId = item.user_id;
            this.deleteDialog = true;
        },
        async confirmDelete() {
            let res = await deleteStudent({ user_id: this.deleteId });
            this.$message.success(res.msg);
            this.getStudentList();
            this.deleteDialog = false;
        },
        selectClass() {
            this.getStudentListParams.page = 1;
            this.getStudentList();
        },
        clearFormData() {
            Object.keys(this.operateParams).forEach((item) => {
                this.operateParams[item] = typeof item == "string" ? "" : -1;
            });
            this.$delete(this.rules.password, 1);
        },
        async downloadTemplate() {
            const res = await downloadStudentTemplate();
            let hosturl = localStorage.getItem("hosturl");
            if (!hosturl && !res.data.url) return false;
            // hosturl = hosturl.replace("http", "https");
            location.href = `${hosturl}/${res.data.url}`;
        },
        openImportDialog() {
            this.importDialog = true;
        },
        openFile() {
            this.$refs.importElForm.validate(() => {
                this.$refs.file.click();
            });
        },
        async confirmImport(e) {
            const { importParams } = this;
            const file = e.target.files[0];
            importParams.file = file;
            const formData = new FormData();
            Object.keys(importParams).forEach((item) => formData.append(item, importParams[item]));
            const res = await importStudent(formData).catch(() => {});
            this.$refs.file.value = null;
            this.$message.success(res.msg);
            this.importDialog = false;
            this.getStudentList();
            this.clearImportForm();
        },
        clearImportForm() {
            Object.keys(this.importParams).forEach((item) => (this.importParams[item] = ""));
        },
        clearAvatar() {
            this.operateParams.avatar = "";
        },
        uploadSuccess(res, file, fileList) {
            this.operateParams.avatar = res.data.url;
            const msg = res.code == 200 ? "上传成功" : res.msg;
            this.$message.success(msg);
        },
        uploadErr(err, file, fileList) {
            this.$message.error("上传失败, 请重新上传");
        },
    },
    mounted() {
        this.init();
    },
};
</script>

<style scoped lang="scss">
.student-manage {
    box-sizing: border-box;
    height: 100%;
    padding: 30px 30px 0;
    .hidden-box {
        display: none;
    }
    .operate-box {
        display: flex;
        justify-content: space-between;
        margin-bottom: 20px;
        .left-filter {
            display: flex;
            align-items: center;
            color: #332d42;
            font-size: 14px;
            .label {
                margin-right: 10px;
            }
            ::v-deep .el-select {
                width: 250px;
                .el-icon-arrow-up:before {
                    content: "\e78f";
                    color: #342e43;
                    font-size: 20px;
                }
            }
        }
        .right-operate-button {
            .plain-button {
                color: #2821fc;
                border-color: #2821fc;
            }
            ::v-deep .el-button + .el-button {
                margin-left: 19px;
            }
        }
    }
}
</style>
