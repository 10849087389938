<template>
  <div class="index">
    <div class="resources-content">
      <div class="scro">
        <div style="height: calc(100%);">
          <el-table :data="liveTable" style="width: 100%; flex: 1;" class="customTable" @sort-change="sort" height="100%">
            <el-table-column label="排名" width="100">
              <template slot-scope="scope">
                <span v-if="scope.row.step == 1"><img style="width: 20px" :src="require('@/assets/image/one.png')" alt="" /></span>
                <span v-else-if="scope.row.step == 2"><img style="width: 20px" :src="require('@/assets/image/two.png')" alt="" /></span>
                <span v-else-if="scope.row.step == 3"><img style="width: 20px" :src="require('@/assets/image/theer.png')" alt="" /></span>
                <span v-else>{{ scope.row.step }}</span>
              </template>
            </el-table-column>

            <el-table-column prop="class_num" label="直播" align="center">
              <template slot-scope="scope">
                <div class="goods-wrapper" >
                  <div class="goods-cover">
                    <img style="border-radius: 8px" :src="scope.row.live_cover" alt="" />
                  </div>
                  <div class="text-overflow-2 goods-name" :title="scope.row.live_title">
                    <span>{{ scope.row.live_title }}</span>
                  </div>
                </div>
              </template>
            </el-table-column>
            <el-table-column prop="create_time" label="学生名称" align="center">
              <template slot-scope="scope">
                <div class="goods-wrapper" >
                  <div class="goods-cover">
                    <img style="border-radius: 25px" :src="scope.row.live_stu_avatar" alt="" />
                  </div>
                  <div class="text-overflow-2 goods-name" :title="scope.row.nickname">
                    <span>{{ scope.row.nickname }}</span>
                  </div>
                </div>
              </template>
            </el-table-column>
            <el-table-column prop="begin_time" label="开播时间" align="center" sortable></el-table-column>
            <el-table-column prop="duration_time" label="直播时长" align="center">
              <template slot-scope="scope">
                <span v-if="scope.row.status===0">未开播</span>
                <span v-if="scope.row.status===1">直播中</span>
                <span v-if="scope.row.status===3">直播暂停</span>
                <span v-if="scope.row.status===2">{{ scope.row.duration_time }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="max_online_num" label="人气峰值" align="center">
              <template slot-scope="scope">
                <span v-if="scope.row.status===2">{{ scope.row.max_online_num }}</span>
                <span v-else>-</span>
              </template>
            </el-table-column>
            <el-table-column prop="watch_num" label="观看人次" align="center" sortable>
              <template slot-scope="scope">
                <span v-if="scope.row.status===2">{{ scope.row.watch_num }}</span>
                <span v-else>-</span>
              </template>
            </el-table-column>
            <el-table-column prop="goods_num" label="商品数" align="center">
              <template slot-scope="scope">
                <span v-if="scope.row.status===2">{{ scope.row.goods_num }}</span>
                <span v-else>-</span>
              </template>
            </el-table-column>
            <el-table-column prop="sales" label="销售额" align="center" sortable>
              <template slot-scope="scope">
                <span v-if="scope.row.status===2">{{ scope.row.sales }}</span>
                <span v-else>-</span>
              </template>
            </el-table-column>
            <el-table-column prop="order_num" label="销量" align="center" sortable>
              <template slot-scope="scope">
                <span v-if="scope.row.status===2">{{ scope.row.order_num }}</span>
                <span v-else>-</span>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
      <el-pagination class="pages-center" :current-page="coursePages.currentPageNum" :page-size="coursePages.eachPageNum" layout="prev, pager, next, jumper" :total="coursePages.total" @current-change="courseCurrentChange">
      </el-pagination>
    </div>
  </div>
</template>
  <script>
import { getLiveRanke } from "@/utils/apis";
export default {
  data() {
    return {
      liveTable: [],
      form: {
        sort_type: "",
        sort: "",
      },
      //分页
      coursePages: {
        currentPageNum: 1,
        eachPageNum: 10,
        total: 0,
      },
    };
  },
  created() {
    this.getDate();
  },
  methods: {
    getDate() {
      let params = {
        train_id: localStorage.getItem("train_id") || "",
        paging: 1,
        page: this.coursePages.currentPageNum,
        pageSize: this.coursePages.eachPageNum,
        sort: this.form.sort,
        sort_type: this.form.sort_type,
      };
      getLiveRanke(params)
        .then((res) => {
          if (res.code == 200) {
            this.liveTable = res.data.list;
            this.coursePages.total = res.data.total;
          }
        })
        .catch((err) => {
          console.error("err", err);
        });
    },
    dataBoard(row) {
      if (row.status == 1 || row.status == 3) {
        let routeUrl = this.$router.resolve({
          name: "trainLiveDatas",
          path: "/livedata/trainLivedatas",
          query: {
            tid: row.user_id,
            bind_id: Number(localStorage.getItem("teacherId")),
            t_Train_id: row.train_id,
            t_Room_id: row.room_id,
          },
        });
        window.open(routeUrl.href, "_blank");
      } else if (row.status == 2) {
        this.$message.warning("该学生直播已结束");
      }
    },
    // timestampToTime(row, column) {
    //   if(row.begin_timestamp !=''){
    //      var date = new Date(row.begin_timestamp * 1000); //时间戳为10位需*1000，时间戳为13位的话不需乘1000
    //       var Y = date.getFullYear() + "-";
    //       var M =
    //         (date.getMonth() + 1 < 10
    //           ? "0" + (date.getMonth() + 1)
    //           : date.getMonth() + 1) + "-";
    //       var D = date.getDate() + " ";
    //       var h = date.getHours() + ":";
    //       var m = date.getMinutes() + ":";
    //       var s = date.getSeconds();
    //       return Y + M + D + h + m + s;
    //   }else{
    //      return "暂未开播"
    //   }

    // },

    sort(column) {
      if (column.order == "ascending") {
        this.form.sort_type = "asc";
        this.form.sort = column.prop;
        this.getDate();
      } else if (column.order == "descending") {
        this.form.sort_type = "desc";
        this.form.sort = column.prop;
        this.getDate();
      }
    },
    // 切换分页
    courseCurrentChange(val) {
      this.coursePages.currentPageNum = val;
      // this.getCommodityList()
      this.getDate();
    },

    seeLive(row) {
      let LiveUrlLast = row.play_url.trim();
      let routeUrl = this.$router.resolve({
        name: "seeStudentLive",
        path: "/toSeeLive/StudentLive",
        query: {
          tid: row.user_id,
          bind_id: Number(localStorage.getItem("teacherId")),
          t_Train_id: row.train_id,
          t_Room_id: row.room_id,
          play_url: LiveUrlLast,
          script_id: row.train_info.script_id,
          advert_id: row.train_info.advert_id,
        },
      });
      window.open(routeUrl.href, "_blank");
    },
  },
};
</script>
  
  
  <style lang='scss' scoped>
.index {
  box-sizing: border-box;
  height: 100%;
  // padding: 20px 20px 0;
}
.resources-content {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.goods-wrapper {
  display: flex;
  align-items: center;
  cursor: pointer;
  .goods-cover {
    width: 44px;
    height: 44px;
    display: flex;
    // background: rgba(64, 66, 109, 0.8) !important;
    border-radius: 4px;
    img {
      width: 100%;
      height: 100%;
     object-fit: cover;
    }
  }
  .goods-name {
    //   width: 1%;
    //   flex: 1;
    margin-left: 10px;
    font-size: 14px;
  }
}
.scro {
  height: 100%;
}
.pages-center {
  // margin: 20px 0 20px;
  text-align: center;
}
::v-deep .customTable th:first-child > .cell {
  padding-left: 30px;
}
::v-deep .customTable td:first-child > .cell {
  padding-left: 30px;
}
::v-deep .customTable th.el-table__cell {
  background: #40426d !important;
  color: #fff !important;
  height: 50px;
}
::v-deep .el-table__body-wrapper {
  background: #101349;
}
::v-deep .customTable td.el-table__cell {
  border-bottom: 1px solid #eeeeee;
  height: 30px;
  padding: 10px 0 !important;
}
::v-deep .el-table .el-table__cel{
    padding: 10px 0 !important;
}
::v-deep .el-table tr {
  background: #101349;
  color: #fff !important;
}
::v-deep
  .customTable.el-table--enable-row-hover
  .el-table__body
  tr:hover
  > td.el-table__cell {
  background: rgba(64, 66, 109, 0.7) !important;
  color: #fff !important;
}
::v-deep .pages-center .el-pager .active {
  background: #101349;
  color: #409EFF !important;
}
::v-deep .el-input__inner {
 
  color: #fff !important;
  background: rgba(75, 69, 255, 0.1);
  border-radius: 2px 2px 2px 2px;
  border: 1px solid rgba(75, 69, 255, 0.2);
}
::v-deep .el-pagination__jump{
    color: #fff !important;  
}
/* 自定义 el-table 滚动条样式 */
.el-table__body-wrapper::-webkit-scrollbar {
  width: 5px; /* 滚动条宽度 */
  height: 5px; /* 横向滚动条的高度 */
}
::v-deep ::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  background: #40426d;
}
::v-deep ::-webkit-scrollbar-thumb {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
//   background-color: rgba(16, 19, 73,.3);
  background-color: #5d58ff;
  border-radius: 10px;

}
::v-deep ::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  border-radius: 3px;
  background: #40426d;
 
}


</style>